import { FC, ReactNode } from 'react';

// COMPONENTS:
import { TabElement, TabElementWithPath } from '../../molecules';

type SingleTabType = {
  label: ReactNode | string;
  number: string;
  isActive: boolean;
  isValid?: boolean;
  pathTo?: string;
  disabled?: boolean;
};

type TabsProps = {
  className?: string;
  content: SingleTabType[];
};

export const Tabs: FC<TabsProps> = ({ className, content }) => {
  return (
    <div className={className}>
      {content.map((tab) => {
        return tab.pathTo ? (
          <TabElementWithPath
            key={tab.number}
            tabNumber={tab.number}
            pathTo={tab.pathTo}
            isValid={tab.isValid}
            isActive={tab.isActive}
            disabled={tab.disabled}
            className='lg:pr-16'
          >
            {tab.label}
          </TabElementWithPath>
        ) : (
          <TabElement
            key={tab.number}
            tabNumber={tab.number}
            isActive={tab.isActive}
            className='lg:pr-16'
          >
            {tab.label}
          </TabElement>
        );
      })}
    </div>
  );
};
