import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

// COMPONENTS:
import Button from '../../atoms/primitive/Button';
import { InternalLink } from '../../atoms';
import { ConfirmationModal } from '../ConfirmationModal';

// STYLES:
import './stepper-navigation.css';

// TYPES
import { AdditionalFormSteps, DataCarryingFormSteps } from '../../../store/config';

// ROUTES
import { RootRoutes } from '../../../router';

type StepperNavigationProps = {
  currentStep: number;
  onBackButtonClick: () => void;
  onNextButtonClick: () => void;
  onCancelButtonClick: () => void;
};

export const StepperNavigation: FC<StepperNavigationProps> = ({
  onBackButtonClick,
  onNextButtonClick,
  onCancelButtonClick,
  currentStep,
}) => {
  const isLastStepActive = currentStep === AdditionalFormSteps.OVERVIEW;
  const isBackButtonVisible = currentStep > DataCarryingFormSteps.PROJECT;

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  return (
    <div
      className={clsx(
        'relative z-103 flex flex-shrink-0 px-6 h-28 bg-other-white',
        'xs:px-8',
        'sm:px-16',
        '2sm:px-20',
        'md:px-24',
        'lg:px-28',
        'xl:px-56',
        '2xl:px-64',
        '3xl:px-80',
        '4xl:px-96'
      )}
    >
      <div className='flex items-center justify-around w-full max-w-5xl'>
        <Button
          variant='cancelSecondary'
          type='button'
          onClick={() => setIsConfirmationModalVisible(true)}
        >
          <FormattedMessage id='GUIDE.NAVIGATION.CANCEL' />
        </Button>

        {isBackButtonVisible && (
          <Button type='button' variant='secondary' onClick={onBackButtonClick}>
            <FormattedMessage id='GUIDE.NAVIGATION.BACK' />
          </Button>
        )}

        {isLastStepActive ? (
          <InternalLink href={RootRoutes.EQUIPMENT} variant='tertiary'>
            <FormattedMessage id='GUIDE.NAVIGATION.SUBMIT' />
          </InternalLink>
        ) : (
          <Button variant='primary' type='button' onClick={onNextButtonClick}>
            <FormattedMessage id='GUIDE.NAVIGATION.NEXT' />
          </Button>
        )}
      </div>

      <ConfirmationModal
        onModalClose={() => setIsConfirmationModalVisible(false)}
        onConfirmButtonClick={onCancelButtonClick}
        isOpen={isConfirmationModalVisible}
      />
    </div>
  );
};
