import { FC } from 'react';

// UTILS
import clsx from 'clsx';
// STYLES
import './start-content.css';

// COMPONENTS
import Button from '../../atoms/primitive/Button';
// import { Video } from '../../molecules';

// TRANSLATIONS
import { FormattedMessage } from 'react-intl';

// ROUTES
import { RootRoutes } from '../../../router';

// TYPES
import { ConfigActionType, DataCarryingFormSteps } from '../../../store/config';

// HOOKS
import { useHistory } from 'react-router-dom';
import { useConfigStore } from '../../../store';

export const StartContent: FC = () => {
  const history = useHistory();
  const { updateConfig } = useConfigStore();

  const goToGuide = () => {
    updateConfig({
      type: ConfigActionType.UPDATE_FORM_STEP,
      payload: DataCarryingFormSteps.PROJECT,
    });
    history.push(RootRoutes.GUIDE);
  };

  return (
    <div className='flex flex-col items-center 2xl:items-start'>
      <div className={clsx('pb-6 pt-6', 'xl:pt-0 xl:text-left')}>
        <h1 className={clsx('text-primary-evinyGreen3 text-h3', 'sm:text-h2', 'md:text-h1')}>
          <FormattedMessage id='START.HEADER.LABEL' />
        </h1>
      </div>
      <div className={clsx('pb-8 pt-2', 'xl:max-w-128')}>
        <h2 className='text-primary-evinyGreen3 text-h3'>
          <FormattedMessage id='START.TIPS.SUBHEADER' />
        </h2>
        <p className='pt-2 text-primary-evinyGreen4 font-secondaryRegular text-body leading-7'>
          <FormattedMessage id='START.TIPS.DESCRIPTION' />
        </p>
      </div>
      <div className='flex flex-col w-full'>
        {/* <Video
            url='https://www.youtube.com/embed/-CtOl8hgYsw?autoplay=0&controls=2&modestbranding=1&showinfo=0&rel=0'
            description={<FormattedMessage id='START.VIDEO.DESCRIPTION' />}
            title='Demo video'
          /> */}
      </div>
      <Button variant='primary' onClick={goToGuide} className='mt-4'>
        <FormattedMessage id='START.BUTTON.LABEL' />
      </Button>
    </div>
  );
};
