import { FC, useContext } from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useJsApiLoader } from '@react-google-maps/api';
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useLoadScript';
import {
  prepareCadastralInformationForDisplay,
  getMapPinPosition,
} from '../../../helpers/functions';

// STORE:
import { ProjectInfoContext } from '../../../store';

// COMPONENTS:
import { GoogleMapWithoutInput, StepHeader } from '../../molecules';
import { TextWithUnderline, OverviewParagraph } from '../../atoms';

// TRANSLATIONS
import { months } from '../../../translations';

// TYPES
import { FormSteps, DataCarryingFormSteps } from '../../../store/config';
import { LocationType, ThermalHeatingType } from '../../../store/project-info';
import { googleMapsApiKey } from '../../../utils/env';

type PreviewStepProps = {
  onChangeStepClick: (goToStep: FormSteps) => void;
};

const GOOGLE_MAPS_API_KEY = googleMapsApiKey ?? '';

const libraries: UseLoadScriptOptions['libraries'] = ['places'];
const jsApiLoaderOptions: UseLoadScriptOptions = {
  id: 'google-map-script',
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  libraries,
};

export const OverviewStep: FC<PreviewStepProps> = ({ onChangeStepClick }) => {
  const { projectInfo } = useContext(ProjectInfoContext);
  const { isLoaded } = useJsApiLoader(jsApiLoaderOptions);

  const intl = useIntl();

  const { locationCadastre, locationAddress, locationType } =
    projectInfo[DataCarryingFormSteps.ADDRESS];

  const isAddressDefined = locationType === LocationType.Address;

  const goToFormStep = (formStep: FormSteps) => () => onChangeStepClick(formStep);

  const prepareStartDateForDisplay = (date: string | Date) => (
    <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.DATE)}>
      {new Date(date).toLocaleDateString('nb-NO')}
    </TextWithUnderline>
  );

  const prepareNumberOfWeeksForDisplay = (numberOfWeeks: number) => (
    <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.DATE)}>
      <FormattedMessage values={{ numberOfWeeks }} {...months[intl.locale]} />
    </TextWithUnderline>
  );

  const prepareThermalHeatingForDisplay = (thermalHeating: ThermalHeatingType | '') => {
    switch (thermalHeating) {
      case ThermalHeatingType.Yes:
        return (
          <>
            <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING' />
            <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.HEATING)}>
              <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.REQUIRED' />
            </TextWithUnderline>
          </>
        );
      case ThermalHeatingType.No:
        return (
          <>
            <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING' />
            <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.HEATING)}>
              <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.NOT.REQUIRED' />
            </TextWithUnderline>
          </>
        );
      case ThermalHeatingType.Unknown:
        return (
          <>
            <TextWithUnderline
              onClick={goToFormStep(DataCarryingFormSteps.HEATING)}
              className='ml-0'
            >
              <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.UNKNOWN.REQUIRED.UNDERLINE' />
            </TextWithUnderline>
            <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.UNKNOWN.REQUIRED.TEXT' />
          </>
        );
      default:
        return '';
    }
  };

  return (
    <div className='2xl:pl-3.5'>
      <StepHeader
        className={clsx('pb-9 text-center', 'lg:text-left')}
        label={<FormattedMessage id='GUIDE.OVERVIEW.TITLE' />}
        subLabel={<FormattedMessage id='GUIDE.OVERVIEW.SUBTITLE' />}
      />

      <div className={clsx('max-w-4xl text-center', 'lg:text-left')}>
        <p
          className={clsx(
            'flex items-center justify-center text-primary-evinyGreen4 text-h3',
            'lg:justify-start'
          )}
        >
          <FormattedMessage id='GUIDE.OVERVIEW.QUESTION' />
        </p>

        <OverviewParagraph className='pt-8'>
          <FormattedMessage id='GUIDE.OVERVIEW.PROJECT.NAME' />
          <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.PROJECT)}>
            {projectInfo[DataCarryingFormSteps.PROJECT].projectName}
          </TextWithUnderline>
        </OverviewParagraph>

        <OverviewParagraph className='pt-10'>
          <FormattedMessage id='GUIDE.OVERVIEW.START.DATE' />
          {prepareStartDateForDisplay(projectInfo[DataCarryingFormSteps.DATE].startDate)}
          <FormattedMessage id='GUIDE.OVERVIEW.NUMBER.OF.WEEKS' />
          {prepareNumberOfWeeksForDisplay(projectInfo[DataCarryingFormSteps.DATE].numberOfWeeks)}
        </OverviewParagraph>

        <OverviewParagraph className='pt-10'>
          <FormattedMessage id='GUIDE.OVERVIEW.WORKING.HOURS' />
          <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.DATE)}>
            {projectInfo[DataCarryingFormSteps.DATE].startWorkingHour}&#8211;
            {projectInfo[DataCarryingFormSteps.DATE].endWorkingHour}
          </TextWithUnderline>
        </OverviewParagraph>

        {isAddressDefined ? (
          <>
            <OverviewParagraph className='pb-6 pt-10'>
              <FormattedMessage id='GUIDE.OVERVIEW.ADDRESS' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.ADDRESS)}>
                {locationAddress.address}
              </TextWithUnderline>
            </OverviewParagraph>
            <GoogleMapWithoutInput
              position={getMapPinPosition(locationAddress.position)}
              className='w-full h-96'
              isLoaded={isLoaded}
            />
          </>
        ) : (
          <>
            <OverviewParagraph className='pt-10'>
              <FormattedMessage id='GUIDE.OVERVIEW.CADASTRAL' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.ADDRESS)}>
                {locationCadastre && prepareCadastralInformationForDisplay(locationCadastre)}
              </TextWithUnderline>
            </OverviewParagraph>
          </>
        )}

        <OverviewParagraph className='pt-10'>
          {typeof projectInfo[DataCarryingFormSteps.AREA].areaSize === 'number' ? (
            <>
              <FormattedMessage id='GUIDE.OVERVIEW.TOTAL.BUILDING.MASS' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.AREA)}>
                {projectInfo[DataCarryingFormSteps.AREA].areaSize}{' '}
                <FormattedMessage id='GUIDE.OVERVIEW.AREA.UNIT' />
              </TextWithUnderline>
            </>
          ) : (
            <>
              <FormattedMessage id='GUIDE.OVERVIEW.TOTAL.BUILDING.MASS' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.AREA)}>
                <FormattedMessage id='GUIDE.OVERVIEW.AREA.NOT.KNOWN' />
              </TextWithUnderline>
            </>
          )}
        </OverviewParagraph>

        <OverviewParagraph className='pt-10'>
          {typeof projectInfo[DataCarryingFormSteps.AREA].intakeEffect === 'number' ? (
            <>
              <FormattedMessage id='GUIDE.OVERVIEW.AREA.INTAKE.EFFECT' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.AREA)}>
                {projectInfo[DataCarryingFormSteps.AREA].intakeEffect}{' '}
                <FormattedMessage id='GUIDE.OVERVIEW.AREA.UNIT.KW' />
              </TextWithUnderline>
            </>
          ) : (
            <>
              <FormattedMessage id='GUIDE.OVERVIEW.AREA.INTAKE.EFFECT' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.AREA)}>
                <FormattedMessage id='GUIDE.OVERVIEW.AREA.NOT.KNOWN' />
              </TextWithUnderline>
            </>
          )}
        </OverviewParagraph>

        <OverviewParagraph className='pt-10'>
          {typeof projectInfo[DataCarryingFormSteps.AREA].topLoad === 'number' ? (
            <>
              <FormattedMessage id='GUIDE.OVERVIEW.AREA.TOP.LOAD' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.AREA)}>
                {projectInfo[DataCarryingFormSteps.AREA].topLoad}{' '}
                <FormattedMessage id='GUIDE.OVERVIEW.AREA.UNIT.KW' />
              </TextWithUnderline>
            </>
          ) : (
            <>
              <FormattedMessage id='GUIDE.OVERVIEW.AREA.TOP.LOAD' />
              <TextWithUnderline onClick={goToFormStep(DataCarryingFormSteps.AREA)}>
                <FormattedMessage id='GUIDE.OVERVIEW.AREA.NOT.KNOWN' />
              </TextWithUnderline>
            </>
          )}
        </OverviewParagraph>

        <OverviewParagraph className='pt-10'>
          {prepareThermalHeatingForDisplay(
            projectInfo[DataCarryingFormSteps.HEATING].thermalHeating
          )}
        </OverviewParagraph>
      </div>
    </div>
  );
};
