import clsx from 'clsx';
import { InputHTMLAttributes, FC } from 'react';

import { DatePickerIcon } from '../../../assets/images';

type InputWithLabelProps = {
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const DatePickerInput: FC<InputWithLabelProps> = ({ className, ...props }) => (
  <div
    className={clsx(
      ' relative z-5 py-3 font-secondaryRegular text-body bg-transparent hover:opacity-50 transition duration-100 ease-in-out',
      className
    )}
  >
    <input {...props} className='w-full bg-transparent cursor-pointer' data-testid='date-picker' />
    <DatePickerIcon className='datepickerIcon absolute -z-1 right-0 top-1/2 mr-4' />
  </div>
);
