import { useContext, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Geocode from 'react-geocode';

// STORE
import { useConfigStore } from '../../../store';

// TYPES
import {
  AddressStepInterface,
  LocationType,
  ProjectInfoContext,
} from '../../../store/project-info';
import { ConfigActionType, DataCarryingFormSteps } from '../../../store/config';
import { googleMapsApiKey } from '../../../utils/env';

const GOOGLE_MAPS_API_KEY = googleMapsApiKey ?? '';

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

export interface UseAddressStep {
  formik: FormikProps<AddressStepInterface>;
  goToNextStep: () => void;
  backToPreviousStep: () => void;
}

export const useAddressStep = (): UseAddressStep => {
  const { formatMessage } = useIntl();
  const { projectInfo } = useContext(ProjectInfoContext);
  const { updateConfig } = useConfigStore();

  const validationSchema = () =>
    Yup.object().shape({
      locationType: Yup.string().required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' })),
      locationAddress: Yup.object().when('locationType', {
        is: LocationType.Address,
        then: Yup.object().shape({
          address: Yup.string().required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' })),
        }),
      }),
      locationCadastre: Yup.object().when('locationType', {
        is: LocationType.Cadastre,
        then: Yup.object().shape({
          municipality: Yup.string().required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' })),
          cadastralUnitNumber: Yup.number()
            .nullable()
            .required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' }))
            .positive(formatMessage({ id: 'ERROR.FIELD.MIN.VALUE' }))
            .max(9999, formatMessage({ id: 'ERROR.FIELD.MAX.VALUE' })),
          propertyUnitNumber: Yup.number()
            .nullable()
            .required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' }))
            .positive(formatMessage({ id: 'ERROR.FIELD.MIN.VALUE' }))
            .max(9999, formatMessage({ id: 'ERROR.FIELD.MAX.VALUE' })),
          leaseNumber: Yup.number()
            .nullable()
            .positive(formatMessage({ id: 'ERROR.FIELD.MIN.VALUE' }))
            .max(9999, formatMessage({ id: 'ERROR.FIELD.MAX.VALUE' })),
          unitNumber: Yup.number()
            .nullable()
            .positive(formatMessage({ id: 'ERROR.FIELD.MIN.VALUE' }))
            .max(9999, formatMessage({ id: 'ERROR.FIELD.MAX.VALUE' })),
        }),
      }),
    });

  const initialValues = useMemo(
    () => ({
      locationType: projectInfo[DataCarryingFormSteps.ADDRESS].locationType,
      locationAddress: {
        address: projectInfo[DataCarryingFormSteps.ADDRESS].locationAddress.address,
        position: {
          lat: projectInfo[DataCarryingFormSteps.ADDRESS].locationAddress.position.lat,
          lng: projectInfo[DataCarryingFormSteps.ADDRESS].locationAddress.position.lng,
        },
      },
      locationCadastre: {
        municipality: projectInfo[DataCarryingFormSteps.ADDRESS].locationCadastre.municipality,
        cadastralUnitNumber:
          projectInfo[DataCarryingFormSteps.ADDRESS].locationCadastre.cadastralUnitNumber,
        propertyUnitNumber:
          projectInfo[DataCarryingFormSteps.ADDRESS].locationCadastre.propertyUnitNumber,
        leaseNumber: projectInfo[DataCarryingFormSteps.ADDRESS].locationCadastre.leaseNumber,
        unitNumber: projectInfo[DataCarryingFormSteps.ADDRESS].locationCadastre.unitNumber,
      },
      completed: projectInfo[DataCarryingFormSteps.ADDRESS].completed,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formik = useFormik<AddressStepInterface>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.AREA,
      });
    },
    validateOnMount: true,
  });

  const goToNextStep = () => {
    formik.submitForm();
  };

  const backToPreviousStep = () => {
    if (formik.isValid) {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.DATE,
      });
    } else {
      formik.submitForm();
    }
  };

  return { formik, goToNextStep, backToPreviousStep };
};
